<template>
<div class="deliver-material">
  <div class="card">
    <div class="common-title">
      <span>{{ $t('临牌办理确认') }}</span>
    </div>
    <van-radio-group v-model="confirmType" direction="horizontal" class="radio-group">
      <van-radio :name="3">{{ $t('未办理') }}</van-radio>
      <van-radio :name="1">{{ $t('已办理') }}</van-radio>
      <van-radio :name="2">{{ $t('正式牌提车') }}</van-radio>
    </van-radio-group>
  </div>
  <div v-if="[0,1].includes(confirmType)" class="card">
    <div class="common-title">
      <span>{{ $t('临牌失效时间') }}</span>
    </div>
    <div class="temp-license-time" @click="showTime=true">{{ expireDate | timeFilter }}</div>
  </div>
  <div class="common-footer">
    <van-button class="btn back" native-type="button" @click.stop="goBack">{{ $t('返回') }}</van-button>
    <van-button class="btn submit" type="submit" @click="onSubmit">{{ $t('提交') }}</van-button>
  </div>
  <van-calendar v-model="showTime" :first-day-of-week="1"  :show-confirm="false" :min-date="minDate" :default-date="defaultValue" @confirm="dateConfirm"/>
</div>
</template>
<script>
import deliveryServices from '@/services/deliveryServices.js'
import loading from '@/utils/loading'
import { dateFormat } from '@/utils'
let vm
export default {
  filters:{
    timeFilter(val){
      if (!val) return `${vm.$t('年')}-${vm.$t('月')}-${vm.$t('日')}`
      return val
    }
  },
  data(){
    return {
      minDate: new Date(),
      defaultValue: null,
      showTime: false,
      taskStatus: '',
      operation: '',
      confirmType: 0,
      expireDate: '',
      params: {
        id: '',
        taskStatus: '4004003',
        executionJson: {
          taskCode: 'TEMP_LICENSE_APPLICATION',
          confirmType: 0,
          expireDate: '',
        },
      },
    }
  },
  beforeCreate(){
    vm=this
  },
  mounted(){
    const { taskId,operation,taskStatus } = this.$route.query
    this.params.id = taskId
    this.operation = operation
    this.taskStatus = taskStatus
    if (this.taskStatus === '4004003'){
      this.getTaskDetail()
    }
  },
  methods:{
    // 获取任务详情
    async getTaskDetail() {
      const params = {
        taskId: this.params.id,
      }
      const res = await deliveryServices.deliveryTaskDetail(params)
      // 回显数据
      this.confirmType = res.executionJson.confirmType
      this.expireDate = res.executionJson.expireDate
    },
    dateConfirm(date) {
      this.expireDate = dateFormat(date,'YYYY-MM-DD')
      this.showTime = false
    },
    goBack(){
      this.$router.go(-1)
    },
    async onSubmit(){
      
      if (this.confirmType === 0 || (this.confirmType === 1 && this.expireDate === '')){
        this.$toast.fail(`${this.$t('请选择')}`)
        return 
      }
      
      this.params.executionJson.confirmType = this.confirmType
      if (this.confirmType===1){
        this.params.executionJson.expireDate = this.expireDate
      }

      loading.showLoading()
      const res = await deliveryServices.deliveryExecution(this.params)
      loading.hideLoading()
      if (res.code === 0) {
        setTimeout(() => {
          this.$router.go(-1)
        }, 1000)
      } else {
        this.$toast(res.msg)
      }
    }
  }
  
}
</script>
<style lang="less" scoped>
.deliver-material{
  padding: 16px;
  padding-bottom: 96px;

  .card{
    padding:  0 12px 4px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    margin-bottom: 10px;
    .sub-title{
      padding: 12px 0;
    }
  }
}
.radio-group{
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.temp-license-time{
  border: 1px solid #f0f0f0;
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  border-radius: 4px;
  color: rgba(13, 23, 26, 0.45);
  cursor: pointer;
}
</style>